import React from 'react';

type JsonTableProps = {
    data: any;
    parentKey?: string;
};

const JsonTableComponent: React.FC<JsonTableProps> = ({ data, parentKey = '' }) => {
    const isObject = (value: any) => typeof value === 'object' && value !== null && !Array.isArray(value);

    return (
        <>
            {Object.entries(data).map(([key, value]) => {
                const fullKey = parentKey ? `${parentKey}.${key}` : key;

                if (isObject(value)) {
                    return (
                        <React.Fragment key={fullKey}>
                            <h2 className="transaction__title">{fullKey}</h2>
                            <div className="row row--obj">
                                <JsonTableComponent data={value} parentKey={fullKey} />
                            </div>
                        </React.Fragment>
                    );
                }

                if (Array.isArray(value)) {
                    return (
                        <React.Fragment key={fullKey}>
                            <h2 className="transaction__title">{fullKey.replace(parentKey + ".", "")}</h2>
                            <div className="row row--arr">
                                {value.map((item, index) => (
                                    <div key={`${fullKey}[${index}]`}>
                                        {isObject(item) ? (
                                            <div className="row row--arr-nested">
                                                <JsonTableComponent data={item} parentKey={`${fullKey}[${index}]`} />
                                            </div>
                                        ) : (
                                            item.toString()
                                        )}
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    );
                }

                if (fullKey && value) {
                    return (
                        <div key={fullKey} className="row row--else">
                            <span className="row__cell">{fullKey.replace(parentKey + ".", "")}:</span><span className="row__val">{value.toString()}</span>
                        </div>
                    );
                }
            })}
        </>
    );
};

export default JsonTableComponent;
