/// React
/// State updates are async so including code straight after a state update means it will run before
/// the state is updated.  Use 'useEffect' on the state object to trigger code after State update
/// 

// Main Dashboard Component.
import React, { useRef, useState, useEffect } from 'react';
import CommonFns from '../common/commonFns';
import SignOutButtonComponent from './signOutButtonComponent';
import axios from 'axios';
import TransactionListComponent from './transactionListComponent';
import TransactionComponent from './transactionComponent';
import { DataGrid, GridToolbar, GridRowsProp, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';


// Global vars sat outside of react render loop becuase of state reseting issues
let accounts: any = null;
let username: string = '';

const DashboardComponent: React.FC<any> = ({ msalAccounts, msalInstance }) => {
    // throw ('Ka-boom!'); //Throw an error to test BoundaryError Component

    // Value to indicate if he dashboard data is updating
    const [isUpdating, setIsUpdating] = useState(false);
    const [extractType, setExtractType] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchText, setSearchText] = useState('');
    const [includeLogSummary, setIncludeLogSummary] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [transactionSearchResults, setTransactionSearchResults] = useState(null);
    const searchRef = useRef(null);

    // Is the app in Error State
    const [isInError, setIsInError] = useState({ hasError: false, message: null });

    const transactionSelectedFromGrid = (transactionNumber: string) => {
        setSelectedTransactionId(transactionNumber);
        getIndividualTransactionData(transactionNumber);
    };

    const transactionViewClosed = () => {
        setSelectedTransaction(null);
    };

    const transactionStateChange = (transactionId: string) => {
        const updatedResults = transactionSearchResults.map((record: any) => {
            if (record.id == transactionId) {
                record.status = 'Loaded Successfully';

                return { ...record };
            }
            // Return the record unchanged
            return record;
        });

        // Update the state with the new array
        setTransactionSearchResults(updatedResults);
    }

    const columns: GridColDef[] = [
        {
            field: 'selectedId', headerName: '', maxWidth: 30, flex: 1, renderCell: (params: GridRenderCellParams<any, string>) => (
                <span className={selectedTransactionId == params.value ? 'selected icon icon--arrow-right--white' : ''}>{selectedTransactionId == params.value ? '>>' : ''}</span>
            ),
        },
        { field: 'createdDate', headerName: 'Date', maxWidth: 100, flex: 1 },
        { field: 'createdTime', headerName: 'Time', maxWidth: 90, flex: 1 },
        { field: 'id', headerName: 'TrnId', maxWidth: 80, flex: 1 },
        {
            field: 'status', headerName: 'Status', maxWidth: 180, flex: 2, renderCell: (params: GridRenderCellParams<any, string>) => (
                <span className={'state state--' + params.value.toLowerCase().replace(/ /g, '-')} >{params.value}</span>
            ),
        },
        { field: 'extractTypeName', headerName: 'Type', maxWidth: 100, flex: 1 },
        { field: 'extractTypePrimaryKey', headerName: 'No.', maxWidth: 120, flex: 1 },
        { field: 'prop1', headerName: 'Partner', flex: 2 },
        { field: 'prop2', headerName: 'ContractNo', maxWidth: 100, flex: 1 },
        { field: 'prop4', headerName: 'AccNo', maxWidth: 100, flex: 1 },
        { field: 'prop3', headerName: 'Customer', flex: 2 },
        { field: 'prop5', headerName: 'SiteIn', flex: 2 },
        {
            field: 'logSummary', headerName: 'Log', minWidth: 100, flex: 2, renderCell: (params: GridRenderCellParams<any, string>) => {
                return (
                    <p dangerouslySetInnerHTML={{ __html: params.value ? params.value.replace(/\r\n/g, '<br/>') : '' }}></p>
                )
            }
        }
    ];

    const closeTransaction = () => {
        transactionViewClosed();
    };

    // Fn triggered when user selects a new extract Type in the drop down list
    const extractTypeSelected = (event: any) => {
        if (event.target.value) {
            setExtractType(event.target.value);
        }
    };

    // Fn triggered when user selects a new day in the drop down calendar control
    const fromDateSelected = (event: any) => {
        if (event.target.value) {
            setFromDate(event.target.value);
            setSearchText('');
        }
    };

    // Fn triggered when user selects a new day in the drop down calendar control
    const toDateSelected = (event: any) => {
        if (event.target.value) {
            setToDate(event.target.value);
            setSearchText('');
        }
    };

    const includeLogSummaryChanged = (event: any) => {
        setIncludeLogSummary(event.target.checked);        
    };

    // Fn triggered when user alters search text
    const searchTextEntered = (event: any) => {
        if (event.target.value) {
            setSearchText(event.target.value);

            if (event.target.value != '') {
                setFromDate('');
                setToDate('');
            }
        }
    };

    // Fn triggered when user presses enter
    const searchKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const search = () => {
        if (fromDate && toDate) {
            getAllTransactionsDataByDate();
        }
        else if (searchText && searchText !== '') {
            getAllTransactionsDataBySearchText();
        }
    }

    // Clear any app error from State to hide the Error pop-up
    const clearError = () => {
        setIsInError(previousState => {
            return { ...previousState, message: null, hasError: false }
        })
    };

    // Set Calendar Search to Today
    const defaultDay = () => {
        const todayDate = CommonFns.getFormattedDateYYMMDD(new Date());
        setFromDate(todayDate);
        setToDate(todayDate);
    };

    // Set Calendar Search to Today
    const defaultWeek = () => {
        const startOfWeekDate = CommonFns.getFormattedDateYYMMDD(CommonFns.getStartOfCurrentWeekDate());
        const endOfWeekDate = CommonFns.getFormattedDateYYMMDD(CommonFns.getEndOfCurrentWeekDate());
        setFromDate(startOfWeekDate);
        setToDate(endOfWeekDate);
    };

    // Retry updating the dashboard data after an error
    const reset = () => {
        setFromDate('');
        setToDate('');
        setSearchText('');
        setExtractType('');
        setIncludeLogSummary(false);
        setTransactionSearchResults(null);
        setSelectedTransaction(null);
        setSelectedTransactionId(null);
        searchRef.current.focus();
    };

    const getAllTransactionsDataByDate = () => {
        setIsUpdating(true);

        //// API_URL is a Global Var substituted by webpack at build time.
        //// Address is configured in the relevant webpack environment file and
        //// pushed into Global scope on build. See globals.tsx
        let url = `${API_URL}/get-by-date?startdate=${CommonFns.getFormattedDateDDMMYYYYFromString(fromDate)}&enddate=${CommonFns.getFormattedDateDDMMYYYYFromString(toDate)}&t=${new Date().getTime()}&includeLogSummary=${includeLogSummary}`;

        if (extractType != "all" && extractType != "") {
            url = `${url}&extracttype=${extractType}`;
        }

        axios.get(url).then(response => {
            if (response.data) {
                const newGridRowsProp: GridRowsProp = response.data.map((row: any) => {
                    // Destruct Additional Properties
                    const [prop1 = "", prop2 = "", prop3 = "", prop4 = "", prop5 = ""] = row.additionalKVP ? row.additionalKVP.split('|') : [];

                    return {
                        selectedId: row.id,
                        createdDate: CommonFns.getFormattedDateDDMMYYYYFromString(row.createdDateTime),
                        createdTime: CommonFns.getFormattedTimeFromString(row.createdDateTime),
                        id: row.id,
                        status: row.stateName,
                        extractTypeName: row.extractTypeName,
                        extractTypePrimaryKey: row.extractTypePrimaryKey,
                        prop1: prop1.replace("Supplier_", "") + " (" + row.qLoaderKey.replace("PartnerIntegration_", "") + ")" || "", // ToDo: Refactor to dynamically set col names from results
                        prop2: prop2.replace("ContractNo_", "") || "",
                        prop3: prop3.replace("Customer_", "") || "",
                        prop4: prop4.replace("CustomerAccNo_", "") || "",
                        prop5: prop5.replace("SiteTown_", "") || "",
                        logSummary: row.logSummary
                    };
                });

                setTransactionSearchResults(newGridRowsProp);
            }
            else {
                setTransactionSearchResults(null);
            }

            searchRef.current.focus();
        }).catch(error => {
            console.log('Axios Error:', error);

            setIsInError(previousState => {
                return { ...previousState, message: error.message, hasError: true }
            });
        }).then(() => {
            setIsUpdating(false);
        });
    };

    const getAllTransactionsDataBySearchText = () => {
        setIsUpdating(true);

        let url = `${API_URL}/search?q=${searchText}&t=${new Date().getTime()}&includeLogSummary=${includeLogSummary}`;

        if (extractType != "all" && extractType != "") {
            url = `${url}&extracttype=${extractType}`;
        }

        axios.get(url).then(response => {
            if (response.data) {
                const newGridRowsProp: GridRowsProp = response.data.map((row: any) => {
                    // Destruct Additional Properties
                    const [prop1 = "", prop2 = "", prop3 = "", prop4 = "", prop5 = ""] = row.additionalKVP ? row.additionalKVP.split('|') : [];

                    return {
                        id: row.id,
                        status: row.stateName,
                        extractTypeName: row.extractTypeName,
                        extractTypePrimaryKey: row.extractTypePrimaryKey,
                        prop1: prop1.replace("Supplier_", "") + " (" + row.qLoaderKey.replace("PartnerIntegration_", "") + ")" || "", // ToDo: Refactor to dynamically set col names from results
                        prop2: prop2.replace("ContractNo_", "") || "",
                        prop3: prop3.replace("Customer_", "") || "",
                        prop4: prop4.replace("CustomerAccNo_", "") || "",
                        prop5: prop5.replace("SiteTown_", "") || ""
                    };
                });

                setTransactionSearchResults(newGridRowsProp);
            }
            else {
                setTransactionSearchResults(null);
            }

            searchRef.current.focus();
        }).catch(error => {
            console.log('Axios Error:', error);

            setIsInError(previousState => {
                return { ...previousState, message: error.message, hasError: true }
            });
        }).then(() => {
            setIsUpdating(false);
        });
    };

    const getIndividualTransactionData = (transactionNumber: string) => {
        setIsUpdating(true);

        axios.get(`${API_URL}/get/${transactionNumber}?t=${new Date().getTime()}`).then(response => {
            if (response.data && response.data.originalDataExtractJson) {
                setSelectedTransaction(response.data);
            }
        }).catch(error => {
            console.log('Axios Error:', error);

            setIsInError(previousState => {
                return { ...previousState, message: error.message, hasError: true }
            });
        }).then(() => {
            setIsUpdating(false);
        });
    };

    async function _acquireAccessToken(msalApp: any) {
        const request = {
            account: accounts[0],
            scopes: [API_SCOPES]
        };

        const authResult = await msalApp.acquireTokenSilent(request);

        return authResult.accessToken;
    }

    // A 'useEffect' runs every render cycle, but if we pass in [] then it only runs once :)
    // Use this to kick off proceedings
    useEffect(() => {
        // Set Msal
        accounts = msalAccounts.accounts;

        if (accounts && accounts[0].username) {
            username = accounts[0].username.split('@')[0];
            const userFirstLastNames: any = username.split('.');
            username = `${userFirstLastNames[1] ? userFirstLastNames[0].substring(0, 1) + '.' + userFirstLastNames[1] : userFirstLastNames[0]}`;

            axios.interceptors.request.use(async (config: any) => {
                const accessToken = await _acquireAccessToken(msalInstance);

                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                return config;
            });
        }
    }, []);

    return (
        <>
            <div className="nav">
                <div className="nav__logo">
                    <img src="images/logo.png" alt="Horizon Platforms logo" />
                </div>
                <div className="nav__options">
                    <SignOutButtonComponent instance={msalInstance} />
                </div>
            </div>
            <div className="dash">
                <div className="dash__search">
                    <label className="search__lbl" htmlFor="fromDate">From</label>
                    <input className="input--date" name="fromDate" type="date" tabIndex={1} onChange={fromDateSelected} value={fromDate} />
                    <label className="search__lbl" htmlFor="toDate">To</label>
                    <input className="input--date input--date2" name="toDate" type="date" tabIndex={2} onChange={toDateSelected} value={toDate} />
                    <label className="search__lbl" htmlFor="extractType">Type</label>
                    <select className="select" name="extractType" tabIndex={3} onChange={extractTypeSelected} value={extractType}>
                        <option value="">All</option>
                        <option value="XHSyrinxPurchaseOrder">XH Purchase Orders</option>
                        <option value="SyrinxToHubspotCustomerUpdate">Syrinx to Hubspot Cust Update</option>
                        <option value="SyrinxToHubspotCustomerFinanceUpdate">Syrinx to Hubspot Fin Update</option>
                        <option value="HubspotToSyrinxCustomerCreate">Hubspot to Syrinx Cust Create</option>
                        <option value="HubspotToSyrinxContactCreate">Hubspot to Syrinx Contact Create</option>
                        <option value="HubspotToSyrinxPropertyUpdate">Hubspot to Syrinx Prop Update</option>
                        <option value="HubspotToAcumaticaCustomerUpdate">Hubspot to Accum Cust Update</option>
                        <option value="AcumaticaToSyrinxCustomerCreate">Accum to Syrinx Cust Create</option>
                        <option value="AcumaticaToSyrinxCustomerUpdate">Accum to Syrinx Cust Update</option>
                    </select>
                    <button className="btn-icon icon icon--small icon--day--charcoal" title="Quick Select Today" aria-label="Quick Select Today" onClick={defaultDay}></button>
                    <button className="btn-icon btn-icon2 icon--small icon--week--charcoal" title="Select This Week Only (Sun > Sat)" aria-label="Select This Week Only (Sun > Sat)" onClick={defaultWeek}></button>
                    <label className="search__lbl" htmlFor="searchtext">Or</label>
                    <input name="searchtext" type="text" ref={searchRef} tabIndex={4} className="search__text" placeholder="Search by anything..." onChange={searchTextEntered} onKeyDown={searchKeyDown} value={searchText} autoFocus />
                    <input id="IncludeLogSummary" name="includeLogSummary" type="checkbox" tabIndex={5} checked={includeLogSummary } onChange={includeLogSummaryChanged} title="Check to include log summary for export" />
                    <span className="btn-icon icon icon--small icon--summary-log--charcoal summary__lbl" title="Check to include log summary for export"></span>
                    <button className="btn btn--primary" type="button" tabIndex={6} onClick={search}>Go</button>
                    <button className="btn btn--secondary" type="button" tabIndex={7} onClick={reset}>Reset</button>
                </div>
                <div className="blades">
                    <div className="blade blade--2">
                        {
                            isUpdating === true &&
                            <div className="splash splash--updating active">
                                <div className="loader"></div>
                            </div>
                        }
                        {
                            isInError.hasError === true &&
                            <div className="splash splash--error active">
                                <div className="error">
                                    <div className="icon icon--error-white"></div>
                                    <h1 className="error__title">Sorry, we have hit a problem!</h1>
                                    <p className="error__text"><span className="dull">err:</span>&nbsp;{isInError.message}</p>
                                    <div className="error__btns">
                                        <button className="btn btn--error" onClick={clearError}>Ok</button>
                                        <button className="btn btn--link" onClick={search}>retry</button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="blade__sub">
                            <div className="head">
                                <div className="title">
                                    <span>Integrations</span>
                                    {selectedTransaction &&
                                        <span onClick={closeTransaction}>
                                            <span>&nbsp;&gt;&nbsp;Transaction</span>
                                            <span className="close">[<span className="icon icon--close--white icon--small"></span>close]</span>
                                        </span>
                                    }
                                </div>
                                <div className="controls"></div>
                            </div>
                            <div className="data">

                                <div className={selectedTransaction === null && transactionSearchResults === null ? 'no-results' : 'hide'}>
                                    <p>No Results - Try a new search...</p>
                                </div>
                                <div className={selectedTransaction === null ? '' : 'hide'}>
                                    <TransactionListComponent rows={transactionSearchResults} columns={columns} transactionSelectedFn={transactionSelectedFromGrid} />
                                </div>
                                <div className={selectedTransaction !== null ? '' : 'hide'}>
                                    <TransactionComponent transaction={selectedTransaction} usersName={username} transactionCloseFn={transactionViewClosed} transactionStateChangeFn={transactionStateChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardComponent;