// Represents a Machine Tile on the Dashboard
import React from 'react';

const SignOutButtonComponent: React.FC<any> = ({ instance }) => {
    const signOutClickHandler = (instance: any) => {
        const logoutRequest = {
            account: instance.getAccountByHomeId(APP_ClientID),
            mainWindowRedirectUri: APP_RedirectURI,
            postLogoutRedirectUri: APP_RedirectURI,
        };
        instance.logoutPopup(logoutRequest);
    }

    return (
        <button className="option" onClick={() => signOutClickHandler(instance)}><span className="icon icon--logout-red" title="Sign Out"></span><span className="text">Sign Out</span></button>
    )
}

export default SignOutButtonComponent;