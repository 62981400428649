import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import DashboardComponent from "./components/dashboardComponent";
import ErrorBoundary from "./components/errorBoundaryComponent";

// Configuration object constructed.
const config = {
    auth: {
        clientId: APP_ClientID,
        authority: "https://login.microsoftonline.com/1456153d-c538-4dcf-b3c6-6faf5a4b5147/v2.0/",
        redirectUri: APP_RedirectURI,
    }
};

// create PublicClientApplication instance
const msalInstance:any = new PublicClientApplication(config);
export const DashboardContext = createContext(null);

// Returns the Dashboard Component, passing in the msalAccount as a prop
// only if authed.
function SetAccount() {
    return <DashboardComponent msalAccounts={useMsal()} msalInstance={ msalInstance } />;
}

// SignInButton Component returns a button that invokes a popup login when clicked
function SignInButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    return <div className="login"><button className="option" onClick={() => signInClickHandler(instance)}><span className="icon icon--login-red" title="Sign In"></span><span className="text">Sign In</span></button></div>
}

function signInClickHandler(instance: any) {
    instance.loginRedirect();
}

const root: any = ReactDOM.createRoot(document.getElementById('app'));
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <AuthenticatedTemplate>
                        <SetAccount />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <p>Please Sign In</p>
                        <SignInButton />
                    </UnauthenticatedTemplate>
                </MsalAuthenticationTemplate>
            </ MsalProvider>
        </ErrorBoundary>
    </React.StrictMode>
);