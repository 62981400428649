import React, { Component, ErrorInfo } from 'react';

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
    error: any,
    errorInfo: any
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // console.log(`ErrorBoundary caught an error:${error}|${errorInfo}`);
        this.setState({ hasError: true, error: error, errorInfo: errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h1>Something went wrong!</h1>
                    <p>Be sure to pass the following info onto your nearest IT Geek who will take a look.</p>
                    <p><strong>Error:</strong> {this.state.error.toString()}</p>
                    <p><strong>Error Info:</strong> {this.state.errorInfo.componentStack}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;