import React, { useState, useEffect } from 'react';
import CommonFns from '../common/commonFns';
import JsonTableComponent from './jsonTableComponent';
import POComponent from './poComponent';
import axios from 'axios';

interface transactionProps {
    transaction: any,
    usersName: any,
    transactionCloseFn: Function,
    transactionStateChangeFn: Function
}

//
const TransactionComponent: React.FC<transactionProps> = ({ transaction, usersName, transactionCloseFn, transactionStateChangeFn }) => {
    const [activeTabName, setActiveTabName] = useState('original');
    const [trns, setTransactionState] = useState(transaction);
    const [trf, setTransformedTransaction] = useState(null);

    const close = () => {
        if (transactionCloseFn) {
            transactionCloseFn();
        }
    };

    const setActiveTab = (tabName: string) => {
        setActiveTabName(tabName);
    };

    const changeStateToSuccessfullyLoaded = () => {
        const logEntry = encodeURIComponent(`${usersName}: Parent Transaction Status updated to Loaded Succesfully via dashboard`);
        axios.post(`${API_URL}/update-transaction-state?etlTransactionId=${transaction.id}&newState=7&logEntry=${logEntry}`
        ).then(response => {
            if (response.status === 200) {
                transaction.state = 7;
                transaction.stateName = "Loaded Successfully";

                setTransactionState((previousState: any) => {
                    return { ...previousState, state: 7, stateName: "Loaded Successfully" }
                });

                if (transactionStateChangeFn) {
                    transactionStateChangeFn(transaction.id);
                }
            }
        }).catch(error => {
            console.log('Axios Error:', error);
        }).then(() => {
        });
    };

    const changeLogTypeToNote = (transactionLog: any) => {
        const logEntry = encodeURIComponent(`${usersName}: Individual Log Entry Status updated via dashboard`);
        axios.post(`${API_URL}/update-transaction-log-state?etlTransactionId=${transaction.id}&etlTransactionLogId=${transactionLog.id}&newState=1&logEntry=${logEntry}`
        ).then(response => {
            if (response.status === 200) {
                transactionLog.logType = 1;
                transactionLog.logTypeName = "Note";
            }
        }).catch(error => {
            console.log('Axios Error:', error);
        }).then(() => {
        });
    };

    // Kick-of Effect
    useEffect(() => {
        if (transaction) {
            console.log('transaction:', transaction);

            if (transaction.transformedData) {
                setTransformedTransaction(JSON.parse(transaction.transformedData.replace(/\n/g, '')));
            }
        }
    }, [trns]);

    return (
        <>
            {transaction &&
                <div className="transaction">
                    <div className="transaction__head">
                        <div className="head__title">
                            <h1 className="transaction__title transaction__title--trans"><span className="title__header">{transaction.extractTypeName}</span>:&nbsp;<span>{transaction.extractTypePrimaryKey}</span></h1>
                            <span className={'state state--' + transaction.stateName.toLowerCase().replace(/ /g, '-')}>{transaction.stateName}</span>
                            {
                                !transaction.stateName.toLowerCase().includes("success") &&
                                <>
                                    <input type="checkbox" name="successfullyLoaded" onClick={() => { changeStateToSuccessfullyLoaded() } }></input>
                                    <label className={'title__label'} htmlFor="successfullyLoaded" >Loaded Successfully</label>
                                </>
                            }
                        </div>
                        <button className="close__btn btn btn-icon icon icon--back--charcoal" onClick={close} title="Go Back to Transaction List" aria-label="Go Back to Transaction List"></button>
                    </div>
                    <div className="tabs">
                        <div className="tabs__header">
                            <button type="button" className={`header__btn ${activeTabName === 'original' ? 'active' : ''}`} onClick={() => { setActiveTab('original') }} data-target="original">Original</button>
                            <button type="button" className={`header__btn ${activeTabName === 'transformed' ? 'active' : ''}`} onClick={() => { setActiveTab('transformed') }} data-target="transformed">Transformed</button>
                            <button type="button" className={`header__btn ${activeTabName === 'infoLog' ? 'active' : ''}`} onClick={() => { setActiveTab('infoLog') }} data-target="transformed">Info Log</button>
                        </div>
                        <div className="tabs__tabs">
                            <div className={`tab ${activeTabName === 'original' ? 'active' : ''}`} data-tab="original">
                                {transaction.extractTypeName === 'XHSyrinxPurchaseOrder' &&
                                    <POComponent transaction={transaction} />
                                }
                            </div>
                            <div className={`tab ${activeTabName === 'transformed' ? 'active' : ''}`} data-tab="transformed">
                                {trf &&
                                    <JsonTableComponent data={trf} />
                                }
                            </div>
                            <div className={`tab ${activeTabName === 'infoLog' ? 'active' : ''}`} data-tab="infoLog">
                                <table className="table" cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Created DateTime</th>
                                            <th>Type</th>
                                            <th>Value</th>
                                            <th>Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transaction.logs.map((item: any, index: number) => (
                                            <tr className={item.logTypeName && item.logTypeName != 'Note' ? 'row--highlight' : ''} key={ 'lgs_' + index }>
                                                <td>{CommonFns.getFormattedDateTimeDDMMYYYYFromString(item.createdDateTime)}</td>
                                                <td>{item.logTypeName}{item.logTypeName.includes('Error') && 
                                                    <input type="checkbox" name="successfullyLoaded" onClick={() => { changeLogTypeToNote(item) }}></input>
                                            
                                                }</td>
                                                <td>{item.value}</td>
                                                <td>{item.additionalErrorCodeName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TransactionComponent;